import React from 'react';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers/LaunchDarkly';
import { EventSponsorship, EventSponsorshipProps } from './EventSponsorship';

export const EventSponsorshipView = (props: EventSponsorshipProps) => {
  const launchDarklyContext = useLaunchDarklyContext();
  const { variation: sponsorshipEnabled = false } = launchDarklyContext?.features['event-sponsorship-enabled'] || {};

  return sponsorshipEnabled ? <EventSponsorship {...props} /> : null;
};
