import { safeFetch } from '../utils';
import { Sponsor, ISchoolSponsors } from '@gf/cross-platform-lib/interfaces';
import { GetCurrentApplicationConfiguration, Method } from '@gf/cross-platform-lib/utils';
import isEmpty from 'lodash/isEmpty';

const appConfig = GetCurrentApplicationConfiguration();

export const fetchSponsor = async (queryParams: { [key: string]: any }) => {
  const url = appConfig.api.sponsor.getUrl!(Method.POST);

  return safeFetch<Sponsor[]>(url!, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      ...queryParams
    })
  });
};

export const fetchSponsorV2 = async (schoolIds: string[]) => {
  const url = `${appConfig.api.sponsorV2.getUrlForMethod!(Method.GET)}?schoolIds=${schoolIds.join(',')}`;

  return safeFetch<ISchoolSponsors>(url);
};

export const fetchEventSponsorMock = async ({ schoolIds, eventIds }: { schoolIds?: string[]; eventIds?: string[] }) => {
  if (!isEmpty(schoolIds)) {
    //Should call safeFetch function when integrated with real API
    return {
      status: 200,
      data: {
        logoUrl: 'event_sponsorship_logo.png',
        name: "Let's create your ideal smiles"
      },
      error: null
    };
  }

  if (!isEmpty(eventIds)) {
    //Should call safeFetch function when integrated with real API
    return {
      status: 200,
      data: {
        logoUrl: 'event_sponsorship_logo.png',
        name: "Let's create your ideal smiles"
      },
      error: null
    };
  }

  return {
    status: 200,
    data: {
      logoUrl: 'event_sponsorship_logo.png',
      name: "Let's create your ideal smiles"
    },
    error: null
  };
};
