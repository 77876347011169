import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { GFImage } from '@gf/cross-platform-lib/components';
import { IEventSponsorship } from '@gf/cross-platform-lib/interfaces';
import { styles } from './EventSponsorship.styles';

export interface EventSponsorshipProps {
  sponsor?: IEventSponsorship;
  padding?: string;
}

const EventSponsorshipContainer = styled.View`
  ${styles.eventSponsorshipContainer}
`;

const SponsorShipText = styled.Text`
  ${styles.sponsorShipText}
`;

const SponsorImageWrapper = styled.View`
  ${styles.sponsorImageWrapper}
`;

const SponsorName = styled.Text`
  ${styles.sponsorName}
`;

export const EventSponsorship = ({ sponsor, padding }: EventSponsorshipProps) => {
  return sponsor?.name ? (
    <EventSponsorshipContainer padding={padding}>
      <SponsorShipText>Sponsored by</SponsorShipText>
      <SponsorImageWrapper>
        <GFImage width={24} height={24} name={sponsor.logoUrl} />
      </SponsorImageWrapper>
      <SponsorName numberOfLines={1}>{sponsor.name}</SponsorName>
    </EventSponsorshipContainer>
  ) : null;
};
