export const DEFAULT_PAGINATION_RESPONSE = {
  content: [],
  first: true,
  last: true,
  number: 0,
  pageable: {
    offset: 0,
    pageNumber: 0,
    pageSize: 200,
    paged: true,
    sort: {
      empty: false,
      sorted: true,
      unsorted: false
    },
    unpaged: false
  },
  size: 200,
  sort: {
    empty: false,
    sorted: true,
    unsorted: false
  },
  totalElements: 0,
  totalPages: 0
};

export const DEFAULT_PAGINATION_REQUEST = {
  page: 0,
  size: 200
};

export const getNextPage = (totalPages: number, pageNumber: number): number | null => {
  return totalPages > pageNumber + 1 ? pageNumber + 1 : null;
};
