import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  eventSponsorshipContainer: css<{ padding: string }>`
    display: flex;
    flex-direction: row;
    padding: ${({ padding = '' }) => padding || '4px 0 0 0'}
    align-items: center;
    width: 100%;
  `,
  sponsorShipText: css<{ disabled?: boolean }>`
    color: #323232;
    font-size: 12px;
    font-family: Rubik-Regular;
    font-weight: normal;
    letter-spacing: 0.32px;
    line-height: 18px;
    white-space: nowrap;
  `,
  sponsorImageWrapper: css`
    margin: 0 4px;
    width: 24px;
    height: 24px;
    background: #ffffff;
    border-radius: 4px;
  `,
  sponsorName: css`
    color: #000000;
    font-size: 14px;
    font-family: Roboto-Regular;
    font-weight: 400;
    letter-spacing: 0.32px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
    flex-grow: 1;
  `
};
