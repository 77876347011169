import isEmpty from 'lodash/isEmpty';
import { StorableType } from '@gf/cross-platform-lib/utils/config/config';
import { fetchEventSponsorMock, fetchSponsor, fetchSponsorV2 } from '../apis';
import { useQuery } from '@tanstack/react-query';
import { buildQueryKey } from '../utils';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers/LaunchDarkly';

export const useFetchSponsorQuery = (queryParams: { [key: string]: any }) => {
  return useQuery({
    queryKey: buildQueryKey([StorableType.Sponsor, queryParams]),
    queryFn: () => fetchSponsor(queryParams),
    enabled: !isEmpty(queryParams)
  });
};

export const useFetchSponsorV2Query = (schoolIds: string[]) => {
  return useQuery({
    queryKey: buildQueryKey([StorableType.SponsorV2, schoolIds]),
    queryFn: () => fetchSponsorV2(schoolIds),
    enabled: !isEmpty(schoolIds)
  });
};

export const useFetchEventSponsorQuery = ({
  eventIds,
  schoolIds,
  enabled = true
}: {
  eventIds?: string[];
  schoolIds?: string[];
  enabled?: boolean;
}) => {
  const launchDarklyContext = useLaunchDarklyContext();
  const { variation: sponsorshipEnabled = false } = launchDarklyContext?.features['event-sponsorship-enabled'] || {};
  return useQuery({
    queryKey: buildQueryKey([StorableType.EventSponsor, [...(eventIds || []), ...(schoolIds || [])]]),
    queryFn: () => fetchEventSponsorMock({ eventIds, schoolIds }),
    enabled: enabled && !!sponsorshipEnabled
  });
};
