import { safeFetch } from '../utils';
import {
  CategoriesType,
  Event,
  IPagination,
  IPaginationResponse,
  ISearchEventFilterParams,
  EventSeason,
  Season
} from '@gf/cross-platform-lib/interfaces';
import {
  DEFAULT_PAGINATION_RESPONSE,
  GetCurrentApplicationConfiguration,
  Method,
  parseQueryParamsToStringV2
} from '@gf/cross-platform-lib/utils';
import isEmpty from 'lodash/isEmpty';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

const appConfig = GetCurrentApplicationConfiguration();

export interface ResponseOptions {
  showLayoutOnError?: boolean;
  redirect404?: boolean;
}

export async function searchEvents(queryParams: ISearchEventFilterParams, pagingParams: IPagination) {
  let allParams: ISearchEventFilterParams = {};

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value !== undefined && key !== 'schoolId') {
      if (Array.isArray(value)) {
        (allParams as any)[key] = value.join(',');
      } else {
        (allParams as any)[key] = value;
      }
    }
  });
  if (queryParams.levels) {
    allParams.levels = queryParams.levels;
  }
  const url = `${appConfig.api.events.baseUrl}/events/search/paginated/${queryParams.schoolId}/${
    pagingParams?.page || 0
  }${!isEmpty(allParams) ? parseQueryParamsToStringV2(allParams) : ''}`;
  return safeFetch<IPaginationResponse<Event>>(url);
}

export async function searchSeasons(queryParams: ISearchEventFilterParams, pagingParams: IPagination) {
  const hasPostSeason = queryParams?.postSeason !== undefined;
  const hasSelectedSchools = queryParams?.selectedSchools !== undefined;

  if (hasPostSeason || hasSelectedSchools) {
    return Promise.resolve({
      status: 200,
      data: DEFAULT_PAGINATION_RESPONSE,
      error: null
    });
  }

  let allParams: ISearchEventFilterParams = {};

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value !== undefined && key !== 'schoolId') {
      if (Array.isArray(value)) {
        (allParams as any)[key] = value.join(',');
      } else {
        (allParams as any)[key] = value;
      }
    }
  });
  if (queryParams.levels) {
    allParams.levels = queryParams.levels;
  }
  const url = `${appConfig.api.events.baseUrl}/seasons/search/paginated/${queryParams.schoolId}/${
    pagingParams?.page || 0
  }${!isEmpty(allParams) ? parseQueryParamsToStringV2(allParams) : ''}`;

  return safeFetch<IPaginationResponse<Season>>(url);
}

export const getAllEventCategories = async (schoolId = '') => {
  const url = `${appConfig.api.events.baseUrl}/events/categories/${schoolId}`;
  return safeFetch<CategoriesType>(url);
};

export const getAllSeasonCategories = async (schoolId = '') => {
  const url = `${appConfig.api.events.baseUrl}/seasons/categories/${schoolId}`;
  return safeFetch<CategoriesType>(url);
};

export const getEvent = async (eventId: string, responseOptions: ResponseOptions) => {
  if (!eventId) {
    const message = 'No event ID provided to getEvent';
    recordError(message, {
      originatingFunction: 'eventApis-getEvent',
      customMessage: 'Attempted to fetch event details with a missing event ID.',
      errorGroup: NEW_RELIC_ERROR_GROUPS.DataIssues,
      expected: true
    });
    throw new Error(message);
  }
  const url = `${appConfig.api.events.baseUrl}/events/${eventId}`;
  return safeFetch<Event>(url, {}, responseOptions);
};

export const getSeason = async (seasonId: string, responseOptions: ResponseOptions) => {
  const url = appConfig.api.seasons.getUrlForMethodAndId!(Method.GET, seasonId);
  return safeFetch<EventSeason>(url!, {}, responseOptions);
};
