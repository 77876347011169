import { LookupTTL, StorableType } from '@gf/cross-platform-lib/utils/config/config';
import {
  getAllEventCategories,
  getAllSeasonCategories,
  getEvent,
  getSeason,
  ResponseOptions,
  searchEvents,
  searchSeasons
} from '../apis';
import {
  Event,
  EventSeason,
  IPagination,
  ISearchEventFilterParams,
  GetEventsQueryContext,
  RetrySettings,
  Season
} from '@gf/cross-platform-lib/interfaces';
import { useLoadMore } from '../utils/useLoadMore';
import { QueryClient, useQueries, useQuery } from '@tanstack/react-query';
import { buildQueryKey, getQuerySSR } from '../utils';
const defaultEventQueryConfigs = {
  refetchOnWindowFocus: false,
  staleTime: 1000 * 20 // 20 seconds
};

export const useSearchSeasonsQuery = (
  filters: ISearchEventFilterParams,
  pagination: IPagination = { page: 0, size: 200 }
) => {
  return useLoadMore<Season, ISearchEventFilterParams>(
    filters,
    pagination,
    searchSeasons,
    [StorableType.Season_List, filters],
    {
      ...defaultEventQueryConfigs,
      cacheTime: LookupTTL[StorableType.Event] // 2 days
    }
  );
};

export const useSearchEventsQuery = (
  filters: ISearchEventFilterParams,
  pagination: IPagination = {
    page: 0,
    size: 200
  }
) => {
  return useLoadMore<Event, ISearchEventFilterParams>(
    filters,
    pagination,
    searchEvents,
    [StorableType.Event_List, filters],
    {
      ...defaultEventQueryConfigs,
      cacheTime: LookupTTL[StorableType.Event] // 2 days
    }
  );
};

export const useGetEventCategoriesQuery = (schoolId = '') => {
  return useQuery({
    queryKey: buildQueryKey([StorableType.Event_Category, schoolId]),
    queryFn: () => getAllEventCategories(schoolId),
    ...defaultEventQueryConfigs,
    enabled: !!schoolId,
    staleTime: 1000 * 60 * 10, // 10 minutes
    cacheTime: LookupTTL[StorableType.Event] // 2 days
  });
};

export const useGetSeasonCategoriesQuery = (schoolId = '') => {
  return useQuery({
    queryKey: buildQueryKey([StorableType.Season_Category, schoolId]),
    queryFn: () => getAllSeasonCategories(schoolId),
    ...defaultEventQueryConfigs,
    enabled: !!schoolId,
    staleTime: 1000 * 60 * 10, // 10 minutes
    cacheTime: LookupTTL[StorableType.Event] // 2 days
  });
};

export const getUseGetEventQueryOptions = (
  eventId: string = '',
  options: ResponseOptions = { showLayoutOnError: true }
) => {
  return {
    queryKey: buildQueryKey([StorableType.Event, eventId]),
    queryFn: () => getEvent(eventId, options)
  };
};

export const useGetEventQuery = (
  eventId: string = '',
  responseOptions: ResponseOptions = {},
  retrySettings?: RetrySettings
) => {
  const defaultOptions = { showLayoutOnError: true };
  const options: ResponseOptions = { ...defaultOptions, ...responseOptions };
  return useQuery({
    ...getUseGetEventQueryOptions(eventId, options),
    ...defaultEventQueryConfigs,
    ...retrySettings,
    enabled: !!eventId
  });
};

export const getEventQuerySSR = async (
  queryClient: QueryClient,
  eventId = '',
  responseOptions: ResponseOptions = {}
) => {
  const defaultOptions: ResponseOptions = { showLayoutOnError: true };
  const options: ResponseOptions = { ...defaultOptions, ...responseOptions };
  const { queryKey, queryFn } = getUseGetEventQueryOptions(eventId, options);
  return getQuerySSR<Event>(queryKey, queryFn, queryClient);
};

export const useGetEventQueries = (
  eventIds: string[] = [],
  responseOptions: ResponseOptions = {},
  retrySettings?: RetrySettings,
  queryContext?: GetEventsQueryContext
) => {
  const { isFromTicketCarousel } = queryContext || {};
  return useQueries({
    queries: eventIds.map(eventId => {
      return {
        queryKey: buildQueryKey([StorableType.Event, eventId]),
        queryFn: () => getEvent(eventId, responseOptions),
        ...defaultEventQueryConfigs,
        ...retrySettings,
        enabled: !!eventId,
        staleTime: isFromTicketCarousel ? 0 : defaultEventQueryConfigs.staleTime
      };
    })
  }).filter(resp => !resp.isFetching && resp.data);
};

export const getUseGetSeasonQueryOptions = (seasonId: string = '', responseOptions: ResponseOptions = {}) => {
  return {
    queryKey: buildQueryKey([StorableType.Season, seasonId]),
    queryFn: () => getSeason(seasonId, responseOptions)
  };
};
export const useGetSeasonQuery = (
  seasonId?: string,
  responseOptions: ResponseOptions = {},
  retrySettings?: RetrySettings
) => {
  return useQuery({
    ...getUseGetSeasonQueryOptions(seasonId, responseOptions),
    ...defaultEventQueryConfigs,
    ...retrySettings,
    enabled: !!seasonId
  });
};

export const useConditionalEventQuery = (eventId: string, isSeason: boolean) => {
  // undefined is used here to take advantage of the `enabled` property of useQuery to
  // prevent the wrong query from running toward non-existent end-points
  //  (see either hook's implementation above) and hitting a 404 (while observing the Rule of Hooks)
  const effectiveEventId = isSeason ? undefined : eventId;
  const effectiveSeasonId = isSeason ? eventId : undefined;
  const eventQueryResult = useGetEventQuery(effectiveEventId);
  const seasonQueryResult = useGetSeasonQuery(effectiveSeasonId);

  return isSeason ? seasonQueryResult : eventQueryResult;
};

export const getSeasonQuerySSR = async (queryClient: QueryClient, seasonId = '') => {
  const { queryKey, queryFn } = getUseGetSeasonQueryOptions(seasonId);
  return getQuerySSR<EventSeason>(queryKey, queryFn, queryClient);
};
