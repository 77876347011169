import React from 'react';
import { SafeFetchResponse, IPagination, IPaginationResponse } from '@gf/cross-platform-lib/interfaces';
import { useInfiniteQuery, UseInfiniteQueryOptions, useQueryClient } from '@tanstack/react-query';
import { DEFAULT_PAGINATION_RESPONSE, getNextPage } from '../../../utils';

type QueryKeyT = [string, object | undefined];

export const useLoadMore = <TResponseContentItem, TFilterParams>(
  initFilters: TFilterParams,
  initPagination: IPagination = { page: 0, size: 200 },
  fetcher: (
    filters: TFilterParams,
    pagination: IPagination
  ) => Promise<SafeFetchResponse<IPaginationResponse<TResponseContentItem>>>,
  queryKeys: QueryKeyT,
  configs?: UseInfiniteQueryOptions<
    SafeFetchResponse<IPaginationResponse<TResponseContentItem>>,
    Error,
    IPaginationResponse<TResponseContentItem>,
    QueryKeyT
  >
) => {
  const queryClient = useQueryClient();
  const { isFetching, isFetchingNextPage, data, hasNextPage, fetchNextPage, refetch, isLoading } = useInfiniteQuery<
    SafeFetchResponse<IPaginationResponse<TResponseContentItem>>
  >({
    queryKey: queryKeys,
    queryFn: ({ pageParam = 0 }) => {
      return fetcher(initFilters, {
        ...initPagination,
        page: pageParam
      });
    },
    getNextPageParam: (lastPage, _pages) => {
      const pageData = lastPage?.data || DEFAULT_PAGINATION_RESPONSE;
      return getNextPage(pageData?.totalPages, pageData?.number);
    },
    staleTime: configs?.staleTime,
    cacheTime: configs?.cacheTime,
    enabled: configs?.enabled || true,
    refetchOnWindowFocus: !!configs?.refetchOnWindowFocus
  });
  const handleRefetch = async () => {
    await queryClient.removeQueries({
      queryKey: queryKeys
    });
    await queryClient.removeQueries({
      queryKey: queryKeys
    });
    await refetch();
  };
  const handeLoadMore = () => {
    hasNextPage && fetchNextPage();
  };
  const items = React.useMemo(() => {
    let rs: TResponseContentItem[] = [];
    (data?.pages || []).forEach(page => {
      rs = rs.concat(page.data?.content || []);
    });
    return rs;
  }, [JSON.stringify(data?.pages || [])]);
  const lastPage = (data?.pages?.length
    ? data?.pages[data?.pages.length - 1].data
    : DEFAULT_PAGINATION_RESPONSE) as unknown as IPaginationResponse<TResponseContentItem>;
  return {
    items,
    lastPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    loadMore: handeLoadMore,
    refetch: handleRefetch
  };
};
